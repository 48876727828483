




















































































































































































































































































































































































































































import { Vue, Component } from "vue-property-decorator";
import api from "@/api";

@Component({
  name: "createCoupon",
  components: {},
})
export default class createCpupon extends Vue {
  pickerOptions = {
      disabledDate(v: any) {
      return v.getTime() < new Date().getTime() - 86400000 || v.getTime() > new Date().getTime() + 3600 * 24 * 365 *1000;
        // return v.getTime() < Date.now() - 24 * 60 * 60 * 1000 || v.getTime() > Date.now() +   365 * 24 * 60 * 60 * 1000 - 24*3600*1000;
    }
  }



  form: any = {
    exclusivePersons: [], //绑定专属经纪人
    belong: "zhike", //渠道 zhike broker
    couponName: "", //优惠劵名称
    couponType: "xjq", // 类型
    valuable: "", //面值
    useCondition: "",// 使用条件 无门栏就是0
    issuedQuantity: '', //发放数量
    validityPeriodStart: "", //有效期开始时间
    validityPeriodEnd: "", //有效期结束时间
    availablePeriod: '', //领卷后有效期限
    rewardPoints: "", //经纪人获得积分
    quantityMark: '', //每人限领
    organizations: [],
    channels: []
  };
  ava_channel_codes: any = []
  useConditions = "1";
  dataVal = [];

  // 可用机构
  showIconOrg = true
  orgList: any = []
  orgListC: any = []
  orgDialog = false
  orgData = []
  keyword = ''
  getOrg() {
    api
      .post('A23005', {keyword: this.keyword})
      .then(res => {
        this.orgDialog = true
        this.orgData = res.list
      })
  }

  handleOrgChange(val: any) {
    this.orgList = val
  }

  sureOrg() {
    if(this.orgList.length != 0) {
      this.showIconOrg = false
      this.orgListC = [...this.orgList]
    }
    this.orgDialog = false
  }

  closeOrgDialog() {
    this.orgDialog = false
  }

  delTag(row: any) {
    this.orgList.splice(this.orgList.findIndex((item: any) => item.outorg_code === row.outorg_code), 1)
    this.orgListC = [...this.orgList]
    if(this.orgListC.length == 0) {
      this.showIconOrg = true
    }
  }
  delAll() {
    // this.orgList = []
    this.orgListC = []
    this.showIconOrg = true
  }
  editOrgList() {
    this.orgDialog = true
  }
  open() {
    let selected = this.orgList//默认要选择复选框的数据
    this.orgDialog = true;
    (this.$refs.table as any).clearSelection();
    selected.forEach((i: any) => {
      this.$nextTick(() => {
        (this.$refs.table as any).toggleRowSelection(
                this.orgData.find(
                        (d: any) => d.outorg_code == i.outorg_code
                ),
                true
        )  // 设置默认选中
      })
    })
  }

  // 可用渠道
  showIconChannel = true
  channelList: any = []
  channelListC: any = []
  channelDialog = false
  channelData = []
  // 搜索条件
  search2: any = {
    name: '',
    bond_type: '',
    project_type: '',
    // pagination: {
    //   page: 0, //页码，从0开始
    //   pageSize: 100, //每页数量
    // },
  };
  total = 0

  getConfig() {
    api
      .post('A23007')
      .then(res => {
        this.config.bond_type = res.bond_type
        this.config.project_type = res.project_type
      })
  }

  getChannel() {
    api
        .post('A23006', this.search2)
        .then(res => {
          this.channelDialog = true
          this.channelData = res.list
        })
  }

  changePage2(page: number) {
    this.search2.pagination.page = page - 1;
    this.getChannel()
  }

  closeChannelDialog() {
    this.channelDialog = false
  }
  handleChannelChange(rows: any){
    let selectedData: any = []
    if (rows) {
      rows.forEach((row: any) => {
        if (row) {
          selectedData.push(row);
        }
      });
    }
    this.channelList = selectedData
  }
  sureChannel() {
    if(this.channelList.length != 0) {
      this.showIconChannel = false
      this.channelListC = [...this.channelList]
    }
    this.channelDialog = false
  }

  delChannelTag(row: any) {
    this.channelList.splice(this.channelList.findIndex((item: any) => item.channel_id === row.channel_id), 1)
    this.channelListC = [...this.channelList]
    if(this.channelList.length == 0) {
      this.showIconChannel = true
    }
  }
  delChannelAll() {
    this.channelListC = []
    this.showIconChannel = true
  }
  editChannelList() {
    this.channelList = [...this.channelListC]
    this.channelDialog = true
  }

  openC() {
    this.channelData = [];
    api.post('A23006', this.search2)
      .then(res => {
        this.channelData = res.list
        let selected = this.channelList;//默认要选择复选框的数据
        (this.$refs as any).table2.clearSelection();
        selected.forEach((item:any) => { // checkedData为已选数据
          this.$nextTick( ()=>{
            this.channelData.find((obj: any) => { // userData 表单数据
              if(item.channel_id === obj.channel_id) {
                (this.$refs.table2 as any).toggleRowSelection(obj,true)
              }
            })
          })
        })
      })


  }

  getRowKey(row: any) {
    return row.code
  }

  // 添加经纪人
  agentDialog = false
  agentData = []
  agentTotal = 0
  search: any = {
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
  };
  add() {
    this.agentDialog = true
    this.searchTable()
  }
  delZsjr() {
    this.zsjbr = [];
    this.form.exclusivePersons = []
    this.showIcon = true
  }
  showIcon = true
  zsjjrObj = {}
  sure() {
    if(this.zsjbr.length != 0) {
      this.showIcon = false
      this.zsjbr.forEach((item: any) => {
        this.form.exclusivePersons.push({
          brokerId: item.brokerId,
          brokerName: item.brokerName
        })
      })
    }
    this.agentDialog = false

  }
  // 专属经纪人
  zsjbr = []
  handleSelectionChange(val: any) {
    console.log(val);
    this.zsjbr = val
  }
  CloseAgentDialog() {
    this.agentDialog = false
  }
  del() {
    this.zsjbr = []
    this.agentData = []
    this.showIcon = true
  }
  changePage(page: number) {
    this.search.pagination.page = page - 1;
    this.searchTable();
  }
  searchTable(search = false) {
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
        .post('J27308', this.search)
        .then(res => {
          this.agentTotal = res.total;
          this.agentData = res.list;
        }).finally(() =>{})
  }

  protected cancle() {
    this.$router.back()
  }

  isIndeterminate = true
  handleCheckAllChange(val: any) {
    this.ava_channel_codes = []
    this.trade.forEach((item: any) => {
      this.ava_channel_codes.push(item.value)
    })
    this.ava_channel_codes = val ? this.ava_channel_codes : [];

    this.isIndeterminate = false;
  }


  /*选择可用渠道*/
  private trade: any = []
  private outorg_code= ''


  /**字典 */
  private config = {
    coupon_type: {
      xjq: "现金券",
      mjq: "满减券",
      zkq: "折扣券",
      jfq: "积分券",
      jfqr: "兑换券"
    },
    project_type: {},
    bond_type: {}
  }


  // 选择优惠券
  changeType(e: any) {

    this.useConditions = '1'
    if(e == 'mjq') {
      this.useConditions = '2'
    }
  }

  //判断整数
  isInteger(obj: any) {
    return obj%1 === 0
  }
  // 创建优惠券
  createLoading = false
  protected CREATECOUPON() {
    if(!this.form.couponName) {
      this.$message.warning('请输入优惠券名称')
      return
    }
    if(!this.form.valuable) {
      this.$message.warning('请输入优惠券面值')
      return
    }
    if(this.useConditions == '1') {
      this.form.useCondition = '0'
    }
    if(this.useConditions == '2' && !this.form.useCondition) {
      this.$message.warning('请输入订单金额')
      return;
    }
    if(!this.form.issuedQuantity) {
      this.$message.warning('请输入发放数量')
      return
    }
    if(!this.isInteger(this.form.issuedQuantity)) {
      this.$message.warning('发放数量必须为整数')
      return
    }
    if(this.dataVal.length == 0) {
      this.$message.warning('请输入有效期')
      return
    }
    if(!this.isInteger(this.form.availablePeriod)){
      this.$message.warning('有效期必须为整数')
      return
    }
    if(!this.form.rewardPoints) {
      this.$message.warning('请输入积分')
      return
    }
    if(!this.form.quantityMark) {
      this.$message.warning('请输入每人限领数量')
      return
    }
    if(!this.isInteger(this.form.quantityMark)) {
      this.$message.warning('每人限领数量必须为整数')
      return
    }

    this.form.channels = []
    this.form.organizations = []
    this.channelListC.forEach((item: any) => {
      this.form.channels.push({
        bindCode: item.channel_id,
        bindName: item.name
      })
    })
    this.orgListC.forEach((item: any) => {
      this.form.organizations.push({
        bindCode: item.code,
        bindName: item.name
      })
    })
    if(!this.form.organizations) {
      this.$message.warning('请选择可用机构')
      return
    }
    if(this.form.channels.length == 0) {
      this.$message.warning('请选择可用渠道')
      return
    }
    this.form.validityPeriodStart = (this.dataVal[0] as any).split(' ')[0] + ' 00:00:00'
    this.form.validityPeriodEnd = (this.dataVal[1] as any).split(' ')[0] + ' 23:59:59'

    this.createLoading = true
    api
      .post('J27301', this.form)
      .then(res => {
        this.$message.success('操作成功')
        this.dataVal = []
        this.channelListC = []
        this.channelList = []
        this.orgListC = []
        this.orgData = []
        this.channelData = []
        this.zsjbr = []
        this.agentData = []
        this.showIconChannel = true
        this.showIcon = true
        this.showIconOrg = true
        this.form = {
          exclusivePersons: [],
          belong: "zhike",
          couponName: "",
          couponType: "xjq",
          valuable: "",
          useCondition: "",
          issuedQuantity: '',
          validityPeriodStart: "",
          validityPeriodEnd: "",
          availablePeriod: '',
          rewardPoints: "",
          quantityMark: '',
          organizations: [],
          channels: []
        }
      }).finally(() => {
      this.createLoading = false
    })
  }

  mounted() {
    this.getConfig()
  }
}
